import React, { useState, useEffect } from 'react';
import { Laptop } from "lucide-react";

const SliderTextBadge = ({handleCallback}) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const totalSlides = 10;

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentSlide((prev) => (prev + 1) % totalSlides);
    }, 3000);

    return () => clearInterval(timer);
  }, []);

  const greetings = [
    "It's amazing how much there is to learn!",
    "No doubts, trading can be a real challenge!",
    "It's very hard to find reliable info, but here we are!",
    "The market is always full of surprises!",
    "Can’t believe how much trading has evolved!",
    "So much to explore, excited to dive in!",
    "It’s crazy how fast things change in trading!",
    "No doubt, trading is a rollercoaster ride!",
    "It’s fascinating how different strategies work!",
    "Totally agree, trading requires constant learning!"
  ];

  const handleClick = () => {
    handleCallback(greetings[currentSlide])
  }

  return (
    <div className="w-full max-w-7xl mx-auto">
      <span onClick={() => {handleClick()}} className="cursor-pointer  mt-5 inline-flex items-center rounded-md bg-gray-400/10 px-2 text-xs font-normal text-gray-400 ring-1 ring-inset ring-gray-400/20">
        Click to use
      </span>
      <h2 onClick={() => {handleClick()}} className="cursor-pointer text-lg font-bold bg-gradient-to-r from-cyan-500 to-green-500 bg-clip-text text-transparent">
        {greetings[currentSlide]}
      </h2>
    </div>
  );
};

export default SliderTextBadge;