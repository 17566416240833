import React, { useEffect, useState, Fragment } from "react";

const SelectField = ({ fields, onChange }) => {
    const [selectedField, setSelectedField] = useState('');
    const handleFieldChange = (e) => {
        const selectedValue = e.target.value;
        setSelectedField(selectedValue);
        if (onChange) {
            onChange(selectedValue);
        }
    };
    return (
        <select className="bg-[#222222] text-gray-300 border border-gray-700 rounded-md text-sm p-2 focus:outline-none focus:ring-2 focus:ring-gray-600" value={selectedField} onChange={handleFieldChange}>
            <option value="">Select Field</option>
            {fields?.map(field => (
                <option key={field.value} value={field.value}>{field.label.replace(/^mua_/, '').replace(/_/g, ' ').toUpperCase()}</option>
            ))}
        </select>
    );
}
export default SelectField;
