/* eslint-disable */
import Api from "./Api";
// import AuthToken from "./AuthTokenServices";
export default {


  //  product Section Start
  async SaveMSG(payload) {
    // await AuthToken.getCurrentAuth();
    const response = await Api().post(`api/dashboard/support-chat-msg`, payload);
    return response;
  },

  async reactToMessage(payload) {
    // await AuthToken.getCurrentAuth();
    const response = await Api().post(`api/dashboard/support-chat-msg/like`, payload);
    return response;
  },

  async getAllMSG(payload) {
    const params = new URLSearchParams(payload).toString();
    // await AuthToken.getCurrentAuth();
    const response = await Api().get(`api/dashboard/support-chat-msg/?${params}`);
    return response;
  },
  async getAllSingleMSG(payload) {
    // await AuthToken.getCurrentAuth();
    const response = await Api().get(`api/dashboard/support-chat-msg/${payload}`);
    return response;
  },
  async setMSGStatus(payload) {
    // await AuthToken.getCurrentAuth();
    const response = await Api().post(`api/dashboard/support-chat-msg/status`, payload);
    return response;
  },
  async deleteMSG(payload) {
    // await AuthToken.getCurrentAuth();
    const response = await Api().post(`api/dashboard/support-chat-msg/remove`, payload);
    return response;
  },

  async importMSG(payload) {
    // await AuthToken.getCurrentAuth();
    const response = await Api().post(`api/dashboard/support-chat-msg/import`, payload);
    return response;
  },
  //  product Section End
}