import React, { useState, useEffect } from "react";
import { FaRedo, FaPlus, FaFire, FaChartLine, FaBookOpen, FaBars, FaMoneyBill, FaThumbsUp, FaThumbsDown, FaCommentDots, FaSearch, FaEllipsisV, FaClock, FaCaretDown, FaPaperPlane, FaPaperclip } from 'react-icons/fa';

import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { FaSpinner } from "react-icons/fa";
import { ClipLoader } from "react-spinners";
import chatTopicServices from "../../../ApiServices/chatTopicServices";
import messageServices from "../../../ApiServices/messageServices";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import { ChatBubbleLeftEllipsisIcon, ChatBubbleLeftIcon, CheckCircleIcon, ChevronDownIcon, ChevronRightIcon, EllipsisVerticalIcon, PlusIcon, UserCircleIcon } from "@heroicons/react/24/outline";
import { CalendarIcon, PaperClipIcon, TagIcon } from '@heroicons/react/20/solid'
import { SearchIcon } from "lucide-react";
import { Label, Listbox, ListboxButton, ListboxOption, ListboxOptions } from '@headlessui/react'
import MainChat from "./components/main-chat";
import Comment from "./components/hashtag-highlight";
import { useParams } from "react-router-dom";
import TextAvatar from "../../../components/TextAvatar";
import { Tooltip } from "react-tooltip";
import Skeleton from "react-loading-skeleton"; // Optional
import "react-loading-skeleton/dist/skeleton.css";


const statuses = {
  Complete: 'text-green-500 bg-green-600/50 ring-green-600/20',
  'In progress': 'text-gray-400 bg-blue-600/50 ring-blue-500/10',
  Closed: 'text-red-400 bg-red-600/50 ring-red-600/20',
}

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}


const comments123 = [
  {
    id: 1,
    name: 'Leslie Alexander',
    imageUrl:
      'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    content:
      'Explicabo nihil laborum. 🌱 Saepe facilis consequuntur in eaque. #Consequatur perspiciatis quam. Sed est illo quia. Culpa vitae placeat vitae. Repudiandae sunt exercitationem nihil nisi facilis placeat minima eveniet.',
    date: '1d ago',
    dateTime: '2023-03-04T15:54Z',
    thread: {
      id: 11,
      count: 3,
      isOpen: false,
      enabled: true,
      threads: [
        {
          id: 2,
          name: 'Michael Foster',
          imageUrl:
            'https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
          content:
            '🕵️‍♀️ Laudantium quidem non et saepe vel sequi accusamus consequatur et. Saepe inventore veniam incidunt cumque et laborum nemo blanditiis rerum. A unde et molestiae autem ad. Architecto dolor ex accusantium maxime cumque laudantium itaque aut perferendis.',
          date: '2d ago',
          dateTime: '2023-03-03T14:02Z',
          type: "comment"
        },
        {
          id: 3,
          name: 'Dries Vincent',
          imageUrl:
            'https://images.unsplash.com/photo-1506794778202-cad84cf45f1d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
          content:
            'Quia animi harum in quis quidem sint. Ipsum dolorem molestias veritatis quis eveniet #commodi assumenda temporibus. Dicta ut modi alias nisi. Veniam quia velit et ut. Id quas ducimus reprehenderit veniam fugit amet fugiat ipsum eius. Voluptas nobis earum in in vel corporis nisi.',
          date: '2d ago',
          dateTime: '2023-03-03T13:23Z',
          type: "comment"
        }
      ]
    }
  },
  {
    id: 2,
    name: 'Michael Foster',
    imageUrl:
      'https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    content:
      'Laudantium quidem non et saepe vel sequi accusamus consequatur et. Saepe inventore veniam incidunt cumque et laborum nemo blanditiis rerum. A unde et molestiae autem ad. Architecto dolor ex accusantium maxime cumque laudantium itaque aut perferendis.',
    date: '2d ago',
    dateTime: '2023-03-03T14:02Z',
    thread: {
      id: 11,
      count: 3,
      isOpen: false,
      enabled: true,
      threads: [
        {
          id: 2,
          name: 'Michael Foster',
          imageUrl:
            'https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
          content:
            'Laudantium quidem non et saepe vel sequi accusamus consequatur et. 🕵️‍♀️ Saepe inventore veniam incidunt cumque et laborum nemo blanditiis rerum. A unde et molestiae autem ad. Architecto dolor ex accusantium maxime cumque laudantium itaque aut perferendis.',
          date: '2d ago',
          dateTime: '2023-03-03T14:02Z'
        }
      ]
    }
  },
  {
    id: 3,
    name: 'Dries Vincent',
    imageUrl:
      'https://images.unsplash.com/photo-1506794778202-cad84cf45f1d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    content:
      'Quia animi harum in quis #quidem sint. Ipsum dolorem molestias veritatis quis eveniet commodi assumenda temporibus. Dicta ut modi alias nisi. 🕵️‍♀️ Veniam quia velit et ut. Id quas ducimus reprehenderit veniam fugit amet fugiat ipsum eius. Voluptas nobis earum in in vel corporis nisi.',
    date: '2d ago',
    dateTime: '2023-03-03T13:23Z',
  },
  {
    id: 4,
    name: 'Lindsay Walton',
    imageUrl:
      'https://images.unsplash.com/photo-1517841905240-472988babdf9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    content:
      '☀️ Unde dolore exercitationem nobis reprehenderit rerum corporis accusamus. Nemo suscipit temporibus quidem dolorum. Nobis optio quae atque blanditiis aspernatur doloribus sit accusamus. Sunt reiciendis ut corrupti ab debitis dolorem dolorem nam sit. Ducimus nisi qui earum aliquam. Est nam doloribus culpa illum.',
    date: '3d ago',
    dateTime: '2023-03-02T21:13Z',
  },
]

const contributors1233 = [
  {
    id: 1,
    name: "Alice Johnson",
    username: "alice_dev",
    avatar: "https://avatars.githubusercontent.com/u/1?v=4", // Replace with actual images
    contributions: 120,
  },
  {
    id: 2,
    name: "Bob Smith",
    username: "bob_code",
    avatar: "https://avatars.githubusercontent.com/u/2?v=4",
    contributions: 98,
  },
  {
    id: 3,
    name: "Charlie Brown",
    username: "charlie_ui",
    avatar: "https://avatars.githubusercontent.com/u/3?v=4",
    contributions: 75,
  },
];


const ForumChat = () => {

  const [threadOpenIds, setThreadOpenIds] = useState([]);
  const [threadReplyId, setThreadReplyId] = useState(null);
  const { thread_id: thread_id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [disableSubmitButton, setDisableSubmitButton] = useState(false);
  const [project, setTopic] = useState(false);
  const [comments, setMsgList] = useState([]);
  const [contributors, setContributors] = useState([]);
  const [message, setMessage] = useState('');

  const messageSchema = Yup.object().shape({
    msg_message: Yup.string().required("Message is required"),
  });

  const checkNumberInArray = (arr, num) => {
    return arr.includes(num);
  }

  const fetchTopics = async () => {
    setIsLoading(true);
    try {
      const res = await chatTopicServices.getAllSingleTopic(thread_id);
      if (res.status) {
        setTopic(res?.data?.data?.transformedTopic || {});
        setMsgList(res?.data?.data?.transformedComments || []);
        setContributors(res?.data?.data?.chatUserrList || []);
      }
    } catch (error) {
      console.error("Error fetching topics:", error);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    if (thread_id > 0) {
      fetchTopics();
    }
  }, [thread_id]);

  const handleSendMessage = async () => {
    setDisableSubmitButton(true);
    const payload = {
      msg_message: message,
      fk_sct_id: thread_id,
      fk_admin_id: localStorage.getItem("user_id") || null,
      fk_parent_msg_id: threadReplyId || null,
    };
    // console.log("payload=>", payload);return false;

    try {
      const res = await messageServices.SaveMSG(payload);
      if (res.status) {
        const newMessage = res.data.data;
        const date = new Date(newMessage.msg_created_at).toLocaleDateString();
        fetchTopics();
        setMessage('');
        setThreadReplyId('');
      }
    } catch (error) {
      console.error("Error sending message:", error);
    } finally {
      setDisableSubmitButton(false);
    }
  };

  const closeThread = async (status) => {
    const payload = {
      sct_id: thread_id,
      sct_is_open: status,
    };
    try {
      const res = await chatTopicServices.closeCompleteThread(payload);
      if (res.status) {
        fetchTopics();
      }
    } catch (error) {
      console.error("Error sending message:", error);
    } finally {

    }

  }

  return (
    <div className="p-4 bg-[#000000] min-h-screen">

      {/* page Title */}
      <div className="flex justify-between items-center mb-2">
        <h3 className="text-2xl font-semibold text-white mb-6">Chat #Thread</h3>
      </div>

      {/* First Account DD LEFT-RIGHT */}
      <div class="grid grid-cols-7 gap-6 mb-5">

        {/* Left Section */}
        <div class="md:col-span-5 col-span-7">

          {/* Topic Data */}
          {/* divide-y divide-gray-800 */}
          <div className="flex justify-between mb-2">
            <div></div>
            <div>
              <button
                onClick={() => {
                  closeThread(1);
                }}
                className="px-4 py-1.5 text-xs font-medium rounded-md bg-blue-600 text-white shadow hover:bg-blue-400 transition  mr-2">
                ✅ Open Thread
              </button>
              <button
                onClick={() => {
                  closeThread(2);
                }}
                className="px-4 py-1.5 text-xs font-medium rounded-md bg-edgePrimary text-black shadow hover:bg-green-400 transition  mr-2">
                ✅ Complete Thread
              </button>
              <button
                onClick={() => {
                  closeThread(3);
                }}
                className="px-4 py-1.5 text-xs font-medium rounded-md bg-red-600 text-white shadow hover:bg-red-700 transition">
                ❌ Close Thread
              </button>
            </div>

          </div>
          <ul role="list">
            {isLoading ? (
              // 🟡 Shimmer Effect (Skeleton Loader)
              <li className="animate-pulse bg-[#151515] rounded-md border-2 border-[#242323] px-4 flex items-center justify-between gap-x-6 py-5">
                <div className="min-w-0">
                  <div className="flex gap-x-3 items-center">
                    <div className="h-6 w-48 bg-gray-700 rounded-md"></div> {/* Simulated title */}
                    <div className="h-4 w-20 bg-gray-700 rounded-md"></div> {/* Simulated status */}
                  </div>
                  <div className="mt-1 flex items-center gap-x-2">
                    <div className="h-4 w-24 bg-gray-700 rounded-md"></div> {/* Simulated date */}
                    <div className="h-4 w-36 bg-gray-700 rounded-md"></div> {/* Simulated creator */}
                  </div>
                </div>
                <div className="flex flex-none items-center gap-x-4">
                  <div className="hidden sm:flex sm:flex-col sm:items-end">
                    <div className="h-4 w-20 bg-gray-700 rounded-md"></div> {/* Simulated role */}
                    <div className="h-4 w-10 bg-gray-700 rounded-md mt-1"></div> {/* Simulated comments */}
                  </div>
                </div>
              </li>
            ) : (
              // ✅ Show actual content when data is available
              <>
                <li
                  key={project?.id}
                  className="bg-[#151515] rounded-md border-2 border-[#242323] px-4 py-5 flex items-center justify-between gap-6"
                >
                  {/* Left Section (Project Info) */}
                  <div className="min-w-0 flex flex-col">
                    <div className="flex items-center gap-3">
                      <p className="text-xl font-semibold text-gray-100">{project?.name}</p>

                      {/* Status Badge */}
                      <p
                        className={classNames(
                          statuses[project?.status],
                          "whitespace-nowrap rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset"
                        )}
                      >
                        {project?.status}
                      </p>
                    </div>

                    {/* Created By & Last Seen */}
                    <div className="mt-2 flex items-center gap-x-2 text-xs text-gray-500">
                      {project?.lastSeen ? (
                        <p className="whitespace-nowrap text-sm">
                          <time dateTime={project?.lastSeenDateTime}>{project?.lastSeen}</time>
                        </p>
                      ) : (
                        <div className="flex items-center gap-x-1.5">
                          <div className="flex-none rounded-full bg-emerald-500/20 p-1">
                            <div className="size-1.5 rounded-full bg-emerald-500" />
                          </div>
                          <p className="text-sm text-gray-500">Online</p>
                        </div>
                      )}

                      <svg viewBox="0 0 2 2" className="size-0.5 fill-current">
                        <circle r={1} cx={1} cy={1} />
                      </svg>
                      <p className="truncate text-sm">Created by {project?.createdBy}</p>
                    </div>
                  </div>

                  {/* Right Section (Buttons) */}


                  <div className="flex flex-none items-center gap-x-4">
                    <div className="hidden sm:flex sm:flex-col sm:items-end">
                      <p className="text-sm text-gray-500">{project?.role}</p>
                      <div className="flex w-16 gap-x-2.5">
                        <dt>
                          <span className="sr-only">Total comments</span>
                          {project?.commentStatus === "resolved" ? (
                            <CheckCircleIcon aria-hidden="true" className="size-6 text-gray-400" />
                          ) : (
                            <ChatBubbleLeftIcon aria-hidden="true" className="size-6 text-gray-400" />
                          )}
                        </dt>
                        <dd className="text-sm/6 text-gray-400">{project?.totalComments}</dd>
                      </div>
                    </div>
                  </div>

                </li></>
            )}
          </ul>

          {/* Chat UI with Replies and tagging etc */}
          <div className="main-chat-thread-section mt-4">

            {/* Today Line with center Text */}
            {/* <div className="relative">
              <div aria-hidden="true" className="absolute inset-0 flex items-center">
                <div className="w-full border-t border-gray-600" />
              </div>
              <div className="relative flex justify-center">
                <button
                  type="button"
                  className="border border-gray-600 inline-flex items-center gap-x-1.5 rounded-full bg-black px-3 py-1.5 text-sm font-medium text-gray-400 shadow-sm"
                >
                  Today
                </button>
              </div>
            </div> */}

            <ul role="list" className="divide-y divide-[#000]">
              {isLoading
                ? // 🟡 Shimmer Effect (Skeleton Loader)
                Array(3)
                  .fill(0)
                  .map((_, index) => (
                    <li key={index} className="flex gap-x-4 py-4 animate-pulse">
                      <div className="size-12 flex-none rounded-full bg-gray-700"></div> {/* Avatar Placeholder */}
                      <div className="flex-auto">
                        <div className="flex items-baseline gap-x-4">
                          <div className="h-4 w-24 bg-gray-700 rounded-md"></div> {/* Name Placeholder */}
                          <div className="h-3 w-20 bg-gray-700 rounded-md"></div> {/* Date Placeholder */}
                        </div>
                        <div className="mt-1 h-4 w-full bg-gray-700 rounded-md"></div> {/* Content Placeholder */}
                        <div className="mt-1 h-4 w-3/4 bg-gray-700 rounded-md"></div> {/* Content Placeholder */}
                      </div>
                    </li>
                  ))
                : comments?.length > 0 && comments?.map((comment) => (
                  <li key={comment.id} className="flex gap-x-4 py-4">
                    <img alt="" src={comment.imageUrl} className="size-12 flex-none rounded-full bg-gray-50 ring-2 ring-gray-500" />
                    <div className="flex-auto">
                      <div className="flex items-baseline gap-x-4">
                        <p className="text-lg font-semibold text-white">{comment.name}</p>
                        <p className="flex-none text-sm text-gray-500">
                          <time dateTime={comment.dateTime}>{comment.date}</time>
                        </p>

                        <span onClick={() => {
                          //handleClick()
                          setThreadReplyId(comment.id);
                        }} className="cursor-pointer inline-flex items-center rounded-md bg-gray-400/10 px-2 py-1 text-xs font-normal text-gray-400 ring-1 ring-inset ring-gray-400/20">
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-4 mr-2">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M7.5 8.25h9m-9 3H12m-9.75 1.51c0 1.6 1.123 2.994 2.707 3.227 1.129.166 2.27.293 3.423.379.35.026.67.21.865.501L12 21l2.755-4.133a1.14 1.14 0 0 1 .865-.501 48.172 48.172 0 0 0 3.423-.379c1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0 0 12 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018Z" />
                          </svg>

                          Reply
                        </span>

                      </div>
                      {/* line-clamp-2  */}
                      <p className="mt-1 text-md text-gray-500">
                        {/* {comment.content} */}
                        <Comment comment={comment.content} />
                      </p>

                      {/* nested chats and discussoin */}
                      <div className="nested-chats mt-2">

                        {
                          comment?.thread?.enabled && (
                            <div className="show">
                              {
                                // !comment?.thread?.isOpen && comment?.thread?.threads?.length > 1 && !checkNumberInArray(threadOpenIds, comment?.id) ? (
                                comment?.thread?.threads?.length > 1 && !checkNumberInArray(threadOpenIds, comment?.id) ? (
                                  <div
                                    onClick={() => {
                                      setThreadOpenIds([...threadOpenIds, comment?.id]);
                                    }}
                                    className="view-thread cursor-pointer bg-[#151515] rounded-md px-3 py-3 inline-flex border shadow-sm shadow-edgePrimary border-gray-600">
                                    <div className="flex items-center">
                                      <div>
                                        <TextAvatar name={comment?.name} bgColor="bg-[#EEEDF0]" textColor="text-black" size="w-12 h-12" />

                                      </div>
                                      <div className="ml-3">
                                        <p className="text-md font-medium">
                                          <span className="text-edgePrimary mr-1">
                                            {comment?.thread?.threads?.length} Messages
                                          </span>
                                          <span className="text-gray-300">
                                            View Thread
                                          </span>
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                ) : (
                                  <div className="text-white">

                                    <div className="flow-root">
                                      <ul role="list" className="-mb-4 mt-4">
                                        {comment?.thread?.threads?.map((activityItem, activityItemIdx) => (
                                          <li key={activityItem.id}>
                                            <div className="relative pb-2">
                                              {activityItemIdx !== comment?.thread?.threads.length - 1 ? (
                                                <span aria-hidden="true" className="absolute left-5 top-5 -ml-px h-full w-0.5 bg-gray-600" />
                                              ) : null}
                                              <div className="relative flex items-start space-x-3">
                                                <div className="relative">
                                                  <img
                                                    alt=""
                                                    src={activityItem.imageUrl}
                                                    className="flex size-10 items-center justify-center rounded-full bg-gray-400 ring-1 ring-gray-600"
                                                  />
                                                </div>
                                                <div className="min-w-0 flex-1">
                                                  <div>
                                                    <div className="text-sm">
                                                      <span className="font-medium text-gray-300">
                                                        {activityItem.name}
                                                      </span>
                                                    </div>
                                                    <p className="mt-0.5 text-sm text-gray-600">Commented {activityItem.date}</p>
                                                  </div>
                                                  <div className="mt-2 text-md text-gray-500">
                                                    <p>
                                                      <Comment comment={activityItem.content} />
                                                    </p>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </li>
                                        ))}
                                      </ul>
                                    </div>

                                  </div>
                                )
                              }
                            </div>
                          )
                        }

                        {
                          comment?.id === threadReplyId && project?.sct_is_open == 1 && (
                            <div className="mt-4">

                              <MainChat isReplying={true} replyingTo={comment?.name} setMessage={setMessage} message={message} handleSendMessage={handleSendMessage} disableSubmitButton={disableSubmitButton} />
                            </div>
                          )
                        }

                      </div>

                    </div>
                  </li>
                ))}
            </ul>

            <div className="akc-chat-box">
              {!threadReplyId && project?.sct_is_open == 1 ? (
                <MainChat isReplying={false} replyingTo={null} setMessage={setMessage} message={message} handleSendMessage={handleSendMessage} />
              ) : (<></>)}
            </div>

          </div>

        </div>

        {/* Right Sectoin */}
        <div class="md:col-span-2 col-span-7">

          <div class="text-left px-6 py-6 bg-[#151515] rounded-md">
            <h1 class="text-lg font-semibold text-white">Top contributions</h1>
            <p class="mt-2 text-pretty text-lg font-medium text-gray-500">These users consistently bring valuable insights, thoughtful discussions, and helpful solutions, making them essential to the community's growth and success.</p>

            <div className="mt-4 flex items-center space-x-4">
              {isLoading
                ? // Shimmer Effect (Skeleton Loader)
                Array(3)
                  .fill(0)
                  .map((_, index) => (
                    <div key={index} className="animate-pulse bg-gray-700 rounded-full w-12 h-12"></div>
                  ))
                : contributors.map((contributor, index) => (
                  <div key={index} className="relative group">
                    {/* <TextAvatar name={contributor} bgColor="bg-[#EEEDF0]" textColor="text-black" size="w-12 h-12" /> */}
                    <img
                      src={`https://api.dicebear.com/7.x/identicon/svg?seed=${contributor}`}
                      alt={contributor}
                      className="w-12 h-12 rounded-full border-2 border-gray-700 shadow-md cursor-pointer transition-transform transform hover:scale-105"
                    />
                    <span className="absolute -top-1 -right-1 bg-yellow-400 text-black text-xs font-bold rounded-full px-1">
                      {contributor}
                    </span>
                  </div>
                ))}

            </div>
          </div>

        </div>

      </div>

    </div>
  );
};

export default ForumChat;
