import React from 'react';

const HashtagHighlight = ({ content }) => {
  
  if (!content || typeof content !== 'string') {
    return null;
  }

  const regex = /#(\w+)/g;

  const highlightedContent = content.split(regex).map((part, index) => {
    if (index % 2 !== 0) {
      return <span key={index} className="text-blue-500">#{part}</span>;
    }
    return part;
  });

  return <>{highlightedContent}</>;
};

const Comment = ({ comment }) => {
  return (
      <HashtagHighlight content={comment} />
  );
};

export default Comment;
