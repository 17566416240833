import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Line } from 'react-chartjs-2';
import Chart from 'chart.js/auto';
import { toast } from "react-toast";
import googleServices from '../../../ApiServices/googleServices';
import { FaSpinner } from "react-icons/fa";

function ServerDashboard() {
    const [serverInfo, setServerInfo] = useState({ cpuUsage: 0, ramUsage: 0 });
    const [isLoading, setIsLoading] = useState(true);
    const [disableSubmitButton, setDisableSubmitButton] = useState(false);
    const [disableSubmitButton1, setDisableSubmitButton1] = useState(false);
    useEffect(() => {
        document.title = "EdgeFin •  Products";
        getServerInfos(3);
    }, []);

    const getServerInfos = async (payload) => {
        setIsLoading(true);
        try {
            const res = await googleServices.getServerInfo(payload)
            if (res.status) {
                console.log("res.data.data=>", res.data.data)
                setServerInfo(res?.data?.data);
                // toast.success(res?.data?.message);
            }
            setIsLoading(false);
        } catch (e) {
            console.log(e, "error in getAllModules")
            toast.error("Fatal Error")
            setIsLoading(false);
        }
    }

    // Fetch server info from the backend

    // Reboot the server

    const rebootServer = async () => {
        setDisableSubmitButton(true);
        setIsLoading(true);
        try {
            await googleServices.getServerReboot()
        } catch (e) {
            console.log(e, "error in getAllModules")
        } finally {
            setIsLoading(false);
            setDisableSubmitButton(false);
            toast.success("Rebooting Server, Please wait for a while")
        }
    }

    const optimizeServer = async () => {
        setDisableSubmitButton1(true);
        setIsLoading(true);
        try {
            await googleServices.getServerOptimize()
        } catch (e) {
            console.log(e, "error in getAllModules")
        } finally {
            setIsLoading(false);
            setDisableSubmitButton1(false);
            toast.success("Server Optimized Successfully")
        }
    }



    // Data for the chart
    const data = {
        labels: ['CPU', 'RAM'],
        datasets: [
            {
                label: 'Usage',
                data: [serverInfo.cpuUsage, serverInfo.ramUsage],
                borderColor: 'rgba(75, 192, 192, 1)',
                tension: 0.1
            }
        ]
    };

    return (
        <>
            <div className="actions mt-2 mb-2">
                <button disabled={disableSubmitButton}
                    className={`ml-4 inline-flex items-center justify-center rounded-md bbt bg-[#27ff10] px-4 py-2 text-sm font-medium text-black shadow-sm hffff  ml-2 ${disableSubmitButton
                        ? "bg-gray-700 hover:bg-gray-700"
                        : ""
                        }`}
                    onClick={rebootServer}>{disableSubmitButton ? (
                        <FaSpinner color="gold" />
                    ) : (
                        "Reboot Server")}</button>
                <button disabled={disableSubmitButton1}
                    className={`ml-4 inline-flex items-center justify-center rounded-md bbt bg-[#27ff10] px-4 py-2 text-sm font-medium text-black shadow-sm hffff  ml-2 ${disableSubmitButton1
                        ? "bg-gray-700 hover:bg-gray-700"
                        : ""
                        }`} onClick={optimizeServer}>
                    {disableSubmitButton1 ? (
                        <FaSpinner color="gold" />
                    ) : (
                        "Optimize Server")}</button>
            </div>
            <div className="bg-[#151515] shadow-md rounded-lg p-6 mb-6">
                <div className="mx-auto flex max-w-2xl items-center justify-between gap-x-3 lg:mx-0 lg:max-w-none pb-3">
                    <div className="flex items-center gap-x-6">
                        <h1>
                            <div className="mt-1 text-base font-semibold leading-6 text-gray-100">CPU</div>
                        </h1>
                    </div>
                </div>

                <div className="bg-[#151515] shadow-md rounded-lg p-6 mb-6">
                    <div className="mx-auto flex max-w-2xl items-center justify-between gap-x-3 lg:mx-0 lg:max-w-none pb-3">
                        <div className="flex items-center gap-x-6">
                            <h1>
                                <div className="mt-1 text-base font-semibold leading-6 text-gray-100">System Information</div>
                            </h1>
                        </div>
                    </div>


                    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">

                        <div className="bg-[#222222] rounded-lg p-4 shadow-md">
                            <div className="text-white text-lg font-semibold mb-2">Used CPU</div>
                            <div className="text-white">{serverInfo?.cpuUsage}%</div>
                        </div>


                        <div className="bg-[#222222] rounded-lg p-4 shadow-md">
                            <div className="text-white text-lg font-semibold mb-2">Free CPU</div>
                            <div className="text-white">{serverInfo?.cpuUnUsage}%</div>
                        </div>


                        <div className="bg-[#222222] rounded-lg p-4 shadow-md">
                            <div className="text-white text-lg font-semibold mb-2">RAM Usage</div>
                            <div className="text-white">{serverInfo?.ramUsage?.toFixed(2)}%</div>
                        </div>
                        <div className="bg-[#222222] rounded-lg p-4 shadow-md">
                            <div className="text-white text-lg font-semibold mb-2">Total Memory</div>
                            <div className="text-white">{serverInfo?.totalMemory?.toFixed(2)} GB</div>
                        </div>

                        <div className="bg-[#222222] rounded-lg p-4 shadow-md">
                            <div className="text-white text-lg font-semibold mb-2">Free Memory</div>
                            <div className="text-white">{serverInfo?.freeMemory?.toFixed(2)} GB</div>
                        </div>

                        <div className="bg-[#222222] rounded-lg p-4 shadow-md">
                            <div className="text-white text-lg font-semibold mb-2">Used Memory</div>
                            <div className="text-white">{serverInfo?.usedMemory?.toFixed(2)} GB</div>
                        </div>

                        <div className="bg-[#222222] rounded-lg p-4 shadow-md">
                            <div className="text-white text-lg font-semibold mb-2">Disk Used</div>
                            <div className="text-white">{serverInfo?.diskUsage}% </div>
                        </div>

                        <div className="bg-[#222222] rounded-lg p-4 shadow-md">
                            <div className="text-white text-lg font-semibold mb-2">NetWork Speed</div>
                            <div className="text-white">{serverInfo?.networkSpeed} </div>
                            <div className="text-white">Upload :{serverInfo?.networkUploadSpeed} </div>
                            <div className="text-white">Download :{serverInfo?.networkDownloadSpeed} </div>
                        </div>


                    </div>
                </div>

            </div>

            <div className="relative isolate overflow-hidden bg-[#151515] border border-gray-50/50 rounded-md">
                {/* Secondary navigation */}
                <header className="pb-4 pt-6 sm:pb-6">
                    <div className="mx-auto flex max-w-7xl flex-wrap items-center gap-6 px-4 sm:flex-nowrap sm:px-6 lg:px-8">
                        <h1 className="text-base font-semibold leading-7 text-white">Server Dashboard</h1>
                        <div className="order-last flex w-full gap-x-8 text-sm font-semibold leading-6 sm:order-none sm:w-auto sm:border-l sm:border-gray-200 sm:pl-6 sm:leading-7">

                        </div>

                        <div className="ml-auto flex items-center gap-x-4">



                        </div>
                    </div>
                </header>
                <div className="text-base font-semibold leading-7 text-white">
                    <div className="charts">
                        <Line data={data} />
                    </div>

                </div>

            </div>



        </>
    );
}

export default ServerDashboard;
