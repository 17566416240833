/* eslint-disable */
import Api from "./Api";
// import AuthToken from "./AuthTokenServices";
export default {


  //  product Section Start
  async SaveTopic(payload) {
    // await AuthToken.getCurrentAuth();
    const response = await Api().post(`api/dashboard/support-chat-topic`, payload);
    return response;
  },

  async getAiThread(payload) {
    const response = await Api().post(`api/dashboard/ai/chat`, payload);
    return response;
  },

  async getAllTopic(payload) {
    // await AuthToken.getCurrentAuth();
    // const response = await Api().get(`api/dashboard/support-chat-topic/?search=${payload}`);

    let isObj = payload !== null && typeof payload === "object" && !Array.isArray(payload);
    const params = new URLSearchParams(payload).toString();
    const response = await Api().get(isObj ? `api/dashboard/support-chat-topic?${params}` : `api/dashboard/support-chat-topic?search=${payload}`);

    return response;
  },
  async getAllTopicList(payload) {
    // await AuthToken.getCurrentAuth();
    // const response = await Api().get(`api/dashboard/support-chat-topic/?search=${payload}`);

    let isObj = payload !== null && typeof payload === "object" && !Array.isArray(payload);
    const params = new URLSearchParams(payload).toString();
    const response = await Api().get(isObj ? `api/dashboard/support-chat-topic/all/topics/list?${params}` : `api/dashboard/support-chat-topic/all/topics/list?search=${payload}`);

    return response;
  },
  async getAllSingleTopic(payload) {
    // await AuthToken.getCurrentAuth();
    const response = await Api().get(`api/dashboard/support-chat-topic/${payload}`);
    return response;
  },
  async setTopicStatus(payload) {
    // await AuthToken.getCurrentAuth();
    const response = await Api().post(`api/dashboard/support-chat-topic/status`, payload);
    return response;
  },

  async closeCompleteThread(payload) {
    // await AuthToken.getCurrentAuth();
    const response = await Api().post(`api/dashboard/support-chat-topic/close-complete`, payload);
    return response;
  },
  async deleteTopic(payload) {
    // await AuthToken.getCurrentAuth();
    const response = await Api().post(`api/dashboard/support-chat-topic/remove`, payload);
    return response;
  },

  async importTopic(payload) {
    // await AuthToken.getCurrentAuth();
    const response = await Api().post(`api/dashboard/support-chat-topic/import`, payload);
    return response;
  },
  //  product Section End
}