import React from 'react';

// Avatar component that takes 'name', 'bgColor', and 'size' as props
const TextAvatar = ({ name, bgColor = 'bg-gray-500', size = 'w-12 h-12', textColor = 'text-white' }) => {
  // Extract initials from the name (first letter of each part of the name)
  const initials = name?.split(' ')?.map(word => word[0]?.toUpperCase())?.join('');

  return (
    <div
      className={`${size} ${bgColor} ${textColor} flex items-center justify-center rounded-full`}
    >
      {initials}
    </div>
  );
};

export default TextAvatar;
