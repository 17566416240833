import React, { useState } from "react";
import { Switch } from "@headlessui/react";

const Settings = ({ data, onStatusChange }) => {
  const userStatusToggle = (condition, classes) => (condition ? `${classes} ${condition}` : classes);

  // Maintain separate state for each switch
  const [stopTrading, setStopTrading] = useState(data.setting_stop_trading);
  const [closeAllTrading, setCloseAllTrading] = useState(data.setting_close_all_trading);
  const [globalConfig, setGlobalConfig] = useState(data.setting_is_global_config);
  const [drawdownLevel, setDrawdownLevel] = useState(data.setting_drawdown_levels || "");

  // Function to handle drawdown level input change
  const handleDrawdownLevelChange = (e) => {
    setDrawdownLevel(e.target.value);
  };
  // Function to update drawdown level
  const handleUpdateDrawdown = () => {
    onStatusChange(data.setting_id, { setting_drawdown_levels: drawdownLevel, setting_stop_trading: stopTrading, setting_close_all_trading: closeAllTrading, setting_is_global_config: globalConfig });
  };

  // Function to handle switch toggle
  const handleSwitchToggle = (key) => {
    if (key === "setting_stop_trading") {
      setStopTrading(!stopTrading);
      onStatusChange(data.setting_id, { [key]: !stopTrading, setting_close_all_trading: closeAllTrading, setting_is_global_config: globalConfig });
    } else if (key === "setting_close_all_trading") {
      setCloseAllTrading(!closeAllTrading);
      onStatusChange(data.setting_id, { [key]: !closeAllTrading, setting_stop_trading: stopTrading, setting_is_global_config: globalConfig });
    } else if (key === "setting_is_global_config") {
      setGlobalConfig(!globalConfig);
      onStatusChange(data.setting_id, { [key]: !globalConfig, setting_close_all_trading: closeAllTrading, setting_stop_trading: stopTrading });
    }
  };

  return (
    <>
      {Object.entries(data).map(([key, value]) => {
        if (key === "setting_stop_trading" || key === "setting_close_all_trading" || key === "setting_is_global_config") {
          const label = key === "setting_stop_trading" ? "Stop Trading" : key === "setting_close_all_trading" ? "Close All Trades" : "Global Config";
          return (
            <div key={key} className="flex flex-col items-center">
              <span className="text-xs font-medium text-white">{label}</span>
              <Switch
                checked={key === "setting_stop_trading" ? stopTrading : key === "setting_is_global_config" ? globalConfig : closeAllTrading}
                onChange={() => handleSwitchToggle(key)}
                className={userStatusToggle(
                  key === "setting_stop_trading" ? stopTrading ? "bg-[#27ff10]" : "bg-gray-200" : key === "setting_is_global_config" ? globalConfig ? "bg-[#27ff10]" : "bg-gray-200" : closeAllTrading ? "bg-[#27ff10]" : "bg-gray-200",
                  "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                )}
              >
                <span className="sr-only">Use setting</span>
                <span
                  aria-hidden="true"
                  className={userStatusToggle(
                    key === "setting_stop_trading" ? stopTrading ? "translate-x-5" : "translate-x-0" : key === "setting_is_global_config" ? globalConfig ? "translate-x-5" : "translate-x-0" : closeAllTrading ? "translate-x-5" : "translate-x-0",
                    "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                  )}
                />
              </Switch>
            </div>
          );
        }
        return null;
      })}

      {/* Input and Button for setting drawdown levels */}
      <div className="flex items-center justify-center mt-6">
        <span className="text-xs font-medium text-white mr-2">Drawdown Level</span>
        <input
          type="number"
          value={drawdownLevel}
          onChange={handleDrawdownLevelChange}
          className="p-1 bg-[#222222] text-xs text-gray-300 border border-gray-300 rounded-md w-24 mr-2 focus:outline-none focus:ring-2 focus:ring-gray-600"
          
          placeholder="Level"
        />
        <button
          onClick={handleUpdateDrawdown}
          className="p-1 text-xs bg-gradient-to-r from-[#dbff06] to-[#27ff10] font-medium text-black rounded"
        >
          Update
        </button>
      </div>
    </>
  );
};

export default Settings;
